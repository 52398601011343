import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import SidebarNav from './components/SidebarNav/SidebarNav';
import { StyledDrawer, classes } from './styles';

export {};
const Sidebar = (props) => {
  const {
    open, variant, disableMenu,
  } = props;

  return (
    <StyledDrawer
      anchor="left"
      variant={variant}
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <SidebarNav
        disableMenu={disableMenu}
      />
    </StyledDrawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disableMenu: PropTypes.bool.isRequired,
};

export default Sidebar;

import React from 'react';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Trans, t } from '@lingui/macro';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { checkPermissions, getCompanies } from '../../helper/userMethods';
import getAllClients from '../../api/admin';
import { CLIENT_ID } from '../../constants/appConstants';
import DropDownSelector from '../../components/DropDownSelector/DropDownSelector';
import useForm from '../../hooks/useForm';

const PREFIX = 'ClientSelection';

const classes = {
  itemHeader: `${PREFIX}-itemHeader`,
  itemContainer: `${PREFIX}-itemContainer`,
  buttonItem: `${PREFIX}-buttonItem`,
};

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`& .${classes.itemHeader}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.itemContainer}`]: {
    backgroundColor: theme.palette.background.container,
    minHeight: 'calc(100vh - 150px)',
    padding: '20px',
  },

  [`& .${classes.buttonItem}`]: {
    paddingTop: 8,
    paddingRight: 8,
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.clientSelection || values.clientSelection.length === 0) {
    errors.clientSelection = t`Please select client`;
  }
  return errors;
};

const ClientSelection = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { data: { data: allCompanies = [] } = [] } = useQuery('GetAllClients', getAllClients);
  const listClients = checkPermissions(user, ['read:selected_companies'])
    ? allCompanies.filter((company) => getCompanies(user).includes(company?.id)) : allCompanies;

  const submitData = async (data) => {
    localStorage.setItem(CLIENT_ID, JSON.stringify(data.clientSelection));
    navigate(0);
  };

  const {
    values,
    handleSubmit,
    errors,
    hasError,
    isFormValid,
    setInputValuesWithName,
    handleBlur,
  } = useForm(
    (data) => submitData(data),
    validate,
    { clientSelection: null },
  );

  return listClients && (
    <StyledGrid container direction="column">
      <Grid item classes={{ root: classes.itemHeader }} xs={12}>
        <Grid
          container
          alignItems="center"
        >
          <Grid item sm="auto" xs={12}>
            <Typography variant="subtitle2">
              <Trans>Company Selection</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item classes={{ root: classes.itemContainer }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <DropDownSelector
              value={values.clientSelection}
              optionList={listClients}
              inputlabel={t`Select Company`}
              handleChange={(value) => setInputValuesWithName('clientSelection', value)}
              fieldName={'clientSelection'}
              isError={hasError('clientSelection')}
              errorMessage={errors.clientSelection}
              displayLabel={(option) => option.name}
              handleBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container classes={{ root: classes.buttonItem }} md={6} xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={!isFormValid}
            color="primary"
            onClick={() => handleSubmit()}
            fullWidth={matches}
          ><Trans>GO</Trans>
          </Button>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default ClientSelection;

import { createTheme } from '@mui/material';
import darkTheme from './darkTheme';
import typography from './darkTheme/typography';

const lightTheme = {
  palette: {
    mode: 'light',
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  typography,
};

const getTheme = (isDarkTheme) => (
  isDarkTheme ? createTheme(darkTheme) : createTheme(lightTheme));

export default getTheme;

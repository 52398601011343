import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// Material UI
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment/moment';
import uuid from 'react-uuid';
import { t, Trans } from '@lingui/macro';

import {
  AS_USER_NAME, AS_USER_ID, AS_USER_SESSION,
  AS_USER_PATH_NAME,
} from '../../constants/appConstants';
import { updateExpireDate } from '../../api/courseActivity';
import WarningDialog from '../../components/WarningDialog';
import useSnackbarContext from '../../hooks/useSnackbarContext';
import useSetLoader from '../../hooks/useSetLoader';
import { getUserProductDetails } from '../../api/users';
import { userActivityLog } from '../../api/customers';
import UserProductInformation from '../../components/UserProductInformation';

const PREFIX = 'UserProductDetails';

const classes = {
  userProductDetailsContainer: `${PREFIX}-userProductDetailsContainer`,
  container: `${PREFIX}-container`,
  itemHeader: `${PREFIX}-itemHeader`,
  iconRoot: `${PREFIX}-iconRoot`,
  actionButton: `${PREFIX}-actionButton`,
  actionMenuPopover: `${PREFIX}-actionMenuPopover`,
  history: `${PREFIX}-history`,
  historyIcon: `${PREFIX}-historyIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.userProductDetailsContainer}`]: {
    height: 'calc(93vh - 165px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },

  [`& .${classes.container}`]: {
    padding: '0px 8px 0px 8px',
  },

  [`& .${classes.itemHeader}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.iconRoot}`]: {
    fontSize: '1rem',
    margin: '5px',
  },

  [`& .${classes.actionButton}`]: {
    marginRight: '10px',
  },

  [`& .${classes.actionMenuPopover}`]: {
    minWidth: '101px',
  },

  [`& .${classes.history}`]: {
    display: 'inline-block',
    borderLeft: '1px solid #fff',
    opacity: '0.6',
  },

  [`& .${classes.historyIcon}`]: {
    padding: '6px',
  },
}));

const UserProductDetails = () => {
  const { id, productId, orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const cache = useQueryClient();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { success, error } = useSnackbarContext();
  const [isDateEditMode, setDateEditMode] = useState(false);
  const [anchorElActionMenu, setAnchorElActionMenu] = useState(null);
  const [isViewAsUser, setIsViewAsUser] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { data: { data: userProductDetails } = {}, isLoading: isFetchingUserProductDetails } = useQuery(['getUserProductDetails', id, productId, orderId], () => getUserProductDetails(id, productId, orderId));

  useEffect(() => {
    const isExpired = moment().isAfter(userProductDetails?.product?.expiration_date);
    setExpireDate(userProductDetails?.product?.expiration_date || new Date());
    setIsSaveDisabled(isExpired);
  }, [userProductDetails]);

  const { mutate: mutateUpdateExpireDate, isLoading: isUpdatingDate } = useMutation(
    updateExpireDate, {
      onSuccess: async () => {
        setDateEditMode(false);
        cache.invalidateQueries('getUserProductDetails');
        success(t`Details have been updated successfully`);
      },
      onError: () => {
        setDateEditMode(false);
        error(t`Failed to update details`);
      },
    },
  );

  const { mutateAsync: mutateUserActivityLog, isLoading: isActivityInprogress } = useMutation(
    userActivityLog,
    {
      onSuccess: ({ data: { impersonate_session_id: sessionId } }) => {
        localStorage.setItem(AS_USER_ID, id);
        localStorage.setItem(AS_USER_NAME, `${userProductDetails?.user?.given_name} ${userProductDetails?.user?.family_name}`);
        localStorage.setItem(AS_USER_SESSION, sessionId);
        localStorage.setItem(AS_USER_PATH_NAME, location.pathname);
        navigate('/myLearning');
      },
      onError: () => {
        error(t`Failed to set user activity`);
      },
    },
  );

  const isAdminUser = userProductDetails?.user?.roles?.some((x) => x.name === 'zosi-admin');

  const getViewAsUserWarningMessage = () => (
    <Grid>
      {t`You are about to view the Learner Portal as this User.`}
      <br />
      {t`Are you sure you want to continue?`}
    </Grid>
  );

  const isDateChange = () => moment(userProductDetails?.product?.expiration_date).isSame(moment(expireDate), 'day');

  const getSaveAndCancelButtons = () => (
    <Grid item>
      <Button
        variant="outlined"
        classes={{ root: classes.actionButton }}
        color="primary"
        fullWidth={matches}
        onClick={() => {
          setDateEditMode(false);
          setExpireDate(userProductDetails?.product?.expiration_date || new Date());
        }}
      ><Trans>Cancel</Trans>
      </Button>

      <Button
        variant="contained"
        color="primary"
        classes={{ root: classes.actionButton }}
        disabled={isSaveDisabled || isDateChange()}
        onClick={() => {
          mutateUpdateExpireDate({
            data: [{
              expirationDate: expireDate,
              courseSeatId: userProductDetails?.product?.id,
            }],
          });
        }}
        fullWidth={matches}
      >
        Save
      </Button>

      <Grid item className={classes.history}>
        <IconButton
          disabled
          className={classes.historyIcon}
          aria-label="history"
          data-testid="activity-history-icon"
          size="large"
        >
          <HistoryIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const getActionButton = () => (
    <Grid item>
      <Button
        classes={{ root: classes.actionButton }}
        variant="contained"
        fontSize="small"
        endIcon={<ArrowDropDownIcon />}
        onClick={(event) => {
          setAnchorElActionMenu(event.currentTarget);
        }}
        color="primary"
        data-testid="action-menu-item"
      >
        <Trans>Actions</Trans>
      </Button>
      <Grid item className={classes.history}>
        <IconButton
          disabled
          className={classes.historyIcon}
          aria-label="history"
          data-testid="activity-history-icon"
          size="large"
        >
          <HistoryIcon />
        </IconButton>
      </Grid>
      <Menu
        id="action-menu"
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorElActionMenu}
        keepMounted
        open={Boolean(anchorElActionMenu)}
        PopoverClasses={{
          paper: classes.actionMenuPopover,
        }}
        onClose={() => {
          setAnchorElActionMenu(null);
        }}
      >
        <MenuItem
          key="user"
          onClick={() => {
            setIsViewAsUser(true);
            setAnchorElActionMenu(false);
          }}
          data-testid="view-as-user"
          disabled={isAdminUser}
        >
          <Trans>View As User</Trans>
        </MenuItem>
        <MenuItem
          key="edit-mode"
          onClick={() => {
            setDateEditMode(true);
            setAnchorElActionMenu(false);
          }}
          disabled={isAdminUser}
          data-testid="edit-mode"
        >
          <Trans>Edit Mode</Trans>
        </MenuItem>
        <MenuItem
          key="generatePDF"
          data-testid="generate-certificate"
          disabled
        >
          <Trans>Generate Certificate</Trans>
        </MenuItem>
        <MenuItem
          key="deletePDF"
          data-testid="delete-certificate"
          disabled
        >
          <Trans>Delete Certificate</Trans>
        </MenuItem>
        <MenuItem
          key="downloadPDF"
          data-testid="download-certificate"
          disabled
        >
          <Trans>Download Certificate</Trans>
        </MenuItem>
      </Menu>

    </Grid>
  );
  useSetLoader('User product details',
    isActivityInprogress || isFetchingUserProductDetails || isUpdatingDate);
  return (
    (
      <Root>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item classes={{ root: classes.itemHeader }} xs={isDateEditMode ? 9 : 10}>
            <Grid container>
              <Grid item>
                <IconButton
                  color="inherit"
                  id="backButton"
                  data-testid="backButton"
                  size="small"
                  classes={{
                    root: classes.iconButton,
                  }}
                  onClick={() => navigate(
                    location?.state?.pathName || `/users/${id}`,
                    {
                      state: {
                        tabValue: location?.state?.tabValue,
                        rowsPerPage: location?.state?.rowsPerPage,
                        pageNo: location?.state?.pageNo,
                        productCount: location?.state?.productCount,
                        orderCount: location?.state?.orderCount,
                        certificateCount: location?.state?.certificateCount,
                        searchTerm: location?.state?.searchTerm,
                      },
                    },
                  )}
                >
                  <ArrowBackIcon
                    classes={{
                      root: classes.iconRoot,
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid xs item classes={{ root: classes.iconRoot }}>
                <Typography variant="h6" display="inline">
                  {userProductDetails?.product?.Course?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            { isDateEditMode ? getSaveAndCancelButtons() : getActionButton() }
          </Grid>
        </Grid>
        <Grid className={classes.userProductDetailsContainer}>
          <UserProductInformation
            product={{
              orderDate: userProductDetails?.product?.Subscription?.Order?.order_date,
              orderNumber: userProductDetails?.product?.Subscription?.Order?.zuora_order_number,
              productId: userProductDetails?.product?.Course?.course_id,
              certificateTemplateName: userProductDetails?.product
                ?.Course
                ?.CertificateTemplate
                ?.name,
              expirationDate: expireDate,
            }}
            isDateEditMode={isDateEditMode}
            isSaveDisabled={isSaveDisabled}
            setExpireDate={setExpireDate}
            setIsSaveDisabled={setIsSaveDisabled}
          />
          <Divider />
          {isViewAsUser && (
            <WarningDialog
              open={isViewAsUser}
              onClose={() => setIsViewAsUser(false)}
              onSubmit={() => {
                mutateUserActivityLog({
                  data: {
                    logEvent: 'impersonate', entity: 'users', entityKey: id, sessionId: uuid(),
                  },
                });
              }}
              warningMessage={getViewAsUserWarningMessage()}
              title={t`User View`}
            />
          )}
        </Grid>
      </Root>
    )
  );
};

export default UserProductDetails;

import { useAuth0 } from '@auth0/auth0-react';

const check = (user, permissions) => {
  if (permissions.length === 0) return true;
  const userPermissions = user['https://zosilearning.com/permissions']?.map(({ permission_name: name }) => name) || [];
  return permissions.some((permission) => userPermissions.includes(permission));
};

function Can(props) {
  const {
    user,
  } = useAuth0();
  return (
    check(user, props.permissions)
      ? props.yes()
      : props.no()
  );
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Trans, t } from '@lingui/macro';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {
  Grid,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { AS_USER_NAME, AS_USER_ID, AS_USER_SESSION } from '../../constants/appConstants';
import WarningDialog from '../WarningDialog';
import useUserActivityLog from '../../hooks/useUserActivityLog';

const PREFIX = 'Index';

const classes = {
  paper: `${PREFIX}-paper`,
  buttonLabel: `${PREFIX}-buttonLabel`,
  button: `${PREFIX}-button`,
  viewAsContainerRoot: `${PREFIX}-viewAsContainerRoot`,
  viewAsUserTitle: `${PREFIX}-viewAsUserTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.paper}`]: {
    padding: '8px 14px',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.disabledIconColor,
  },

  [`& .${classes.buttonLabel}`]: {
    textTransform: 'none',
    fontSize: 12,
  },

  [`& .${classes.button}`]: {
    borderRadius: 2,
  },

  [`& .${classes.viewAsContainerRoot}`]: {
    paddingTop: '7px',
  },

  [`& .${classes.viewAsUserTitle}`]: {
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const Index = () => {
  const viewAsUserName = localStorage.getItem(AS_USER_NAME);
  const [userWarning, setUserWarning] = useState(null);

  const viewAsUserId = localStorage.getItem(AS_USER_ID) || '';
  const viewAsUserSessionId = localStorage.getItem(AS_USER_SESSION) || '';
  const queryClient = useQueryClient();
  const { mutateUserActivityLog } = useUserActivityLog(
    viewAsUserId, setUserWarning,
  );
  return (
    (
      <Root>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid item>
              <Grid container classes={{ root: classes.viewAsContainerRoot }} spacing={2}>
                <Grid item>
                  <Typography display="inline" variant="body2" classes={{ root: classes.languageHeader }}>
                    <Trans>Viewing as:</Trans>
                  </Typography>
                </Grid>
                <Grid item className={classes.viewAsUserTitle}>
                  <Typography display="inline" variant="body2" classes={{ root: classes.languageHeader }}>
                    {viewAsUserName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                classes={{
                  label: classes.button,
                  root: classes.buttonLabel,
                }}
                onClick={() => {
                  setUserWarning(true);
                }}
              >
                <Trans>Exit User View</Trans>
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {userWarning && (
          <WarningDialog
            open={userWarning}
            onClose={() => setUserWarning(false)}
            onSubmit={() => {
              mutateUserActivityLog({
                data: {
                  logEvent: 'impersonate_exit', entity: 'users', entityKey: viewAsUserId, sessionId: viewAsUserSessionId,
                },
              });
              queryClient.removeQueries(['myLearningListPagination']);
            }}
            warningMessage={t`Are you sure you want to Exit User View and return to Admin View? `}
            title={t`Confirmation`}
            postiveButtonText={t`Continue`}
            cancelButtonText={t`Cancel`}
          />
        )}
      </Root>
    )
  );
};

export default Index;

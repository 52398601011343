import moment from 'moment-timezone';
import { DATE_SHORT_FORMAT, DATE_SHORT_TIME_FORMAT } from '../constants/appConstants';

const expiryDate = (dateString) => {
  const expiration = moment(dateString).format(DATE_SHORT_FORMAT);
  const currentDate = moment().format(DATE_SHORT_FORMAT);
  const days = moment(expiration).diff(currentDate, 'days');
  return days;
};

export const getLocalizedEventDate = (eventDate, format, timezone) => moment.utc(eventDate)
  . tz(timezone).format(format);

export const getJoinEvent = (iseventDay, isEventExpire) => {
  if (iseventDay && !isEventExpire) {
    return true;
  }
  return false;
};

export const getEventUtcDate = (eventDate, format, timezone) => {
  const utcOffset = moment.tz(eventDate, timezone).utcOffset() / 60;
  return moment(eventDate).subtract(utcOffset, 'hours').format(format);
};

export const getExpiryDate = (expirationDate) => `${moment.utc(expirationDate.split('T')[0]).subtract(1, 'seconds').format(DATE_SHORT_TIME_FORMAT)} UTC`;

export default expiryDate;

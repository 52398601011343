import React, { Suspense, lazy } from 'react';
import {
  Routes, Navigate, useLocation, Route,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';

// Layouts
import RouteWithLayout, { PrivateLayoutRoute as PrivateComponent, AuthenticateForAdmin } from './components/RouteWithLayout/RouteWithLayout';
import Layouts from './layouts';
import { CLIENT_ID } from './constants/appConstants';
import UserProductDetails from './containers/UserProductDetails';

// Containers
const ClientDetails = lazy(() => import('./containers/ClientDetails'));
const Downloads = lazy(() => import('./containers/Downloads'));
const UserListing = lazy(() => import('./containers/UserListing'));
const CreateUser = lazy(() => import('./containers/CreateUser'));
const CreateCustomer = lazy(() => import('./containers/CreateCustomer'));
const NotFound = lazy(() => import('./containers/NotFound'));
const LibraryDetails = lazy(() => import('./containers/LibraryDetails'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const UserDetail = lazy(() => import('./containers/UserDetail'));
const Profile = lazy(() => import('./containers/Profile'));
const supportCenter = lazy(() => import('./components/SupportCenter'));
const MyLearning = lazy(() => import('./containers/MyLearning'));
const ManageEnrollment = lazy(() => import('./containers/ManageEnrollment'));
const MyOrders = lazy(() => import('./containers/MyOrders'));
const Orders = lazy(() => import('./containers/Orders'));
const OrderDetail = lazy(() => import('./containers/OrderDetail'));
const MyCertificates = lazy(() => import('./containers/MyCertificates'));
const Customers = lazy(() => import('./containers/Customers'));
const WelcomePage = lazy(() => import('./containers/WelcomePage'));
const CustomerDetail = lazy(() => import('./containers/CustomerDetail'));
const CustomerOrderDetail = lazy(() => import('./containers/CustomerOrderDetail'));
const CourseActivityDetails = lazy(() => import('./containers/CourseActivityDetails'));
const CertificateVerification = lazy(() => import('./containers/CertificateVerification'));
const EventDetail = lazy(() => import('./containers/EventDetail/EventDetail'));
const ManageEnrollmentOrderDetail = lazy(() => import('./containers/ManageEnrollmentOrderDetail/ManageEnrollmentOrderDetail'));
const OrganizeEvents = lazy(() => import('./containers/OrganizeEvents'));
const OrganizeEventsDetail = lazy(() => import('./containers/OrganizeEventsDetail/OrganizeEventsDetail'));
const OrderProductSeatDetails = lazy(() => import('./containers/OrderProductSeatDetails'));
const OrderBundleSeatDetails = lazy(() => import('./containers/OrderBundleSeatDetails'));
const CertificateCredentialsView = lazy(() => import('./containers/CertificateCredentialsView'));

const { Main, Empty } = Layouts;

const RootRouter = (appProps) => {
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');

  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get('clientid');
  const name = query.get('clientname');
  if (id && name) localStorage.setItem(CLIENT_ID, JSON.stringify({ id, name }));
  const isFromAdminQuery = !!query.get('isauthenticated');
  if (isFromAdminQuery) localStorage.setItem('isFromAdmin', isFromAdminQuery);
  const isFromAdmin = JSON.parse(localStorage.getItem('isFromAdmin'));
  let PrivateLayoutRoute = PrivateComponent;
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    localStorage.removeItem('isFromAdmin');
    PrivateLayoutRoute = PrivateComponent;
  }

  if (isFromAdmin) {
    PrivateLayoutRoute = AuthenticateForAdmin;
  }

  if (pathname === '/:url*(/+)') {
    return <Navigate to={pathname?.slice(0, -1)} />;
  } if (pathname === '/' || pathname === '/downloads') {
    return <Navigate to="/downloads/libraries" />;
  } if (pathname === '/index.html') {
    return <Navigate to="/login" />;
  } if (pathname === '/myLearning') {
    return <Navigate to="/myLearning/all" />;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading isLoading />}>
        <Routes>
          <Route
            path="/login"
            {...appProps}
            element={(
              <RouteWithLayout
                component={LoginPage}
                layout={Empty}
              />
            )}
          />
          <Route
            path="/welcome"
            {...appProps}
            element={(
              <RouteWithLayout
                component={WelcomePage}
                layout={Empty}
              />
            )}
          />
          <Route
            path="/certificateVerification"
            {...appProps}
            element={(
              <RouteWithLayout
                component={CertificateVerification}
                layout={Empty}
              />
            )}
          />
          <Route
            path="/verify/credentials/:id"
            {...appProps}
            element={(
              <RouteWithLayout
                component={CertificateCredentialsView}
                layout={Empty}
              />
            )}
          />
          <Route
            path="/profile"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={Profile}
                layout={Main}
              />
            )}
          />
          <Route
            path="/downloads/:tabs"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={Downloads}
                layout={Main}
              />
            )}
          />
          <Route
            path="/downloads/libraries/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={LibraryDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/clientDetails"
            exact
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={ClientDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={isFeatureEnabled ? Customers : UserListing}
                layout={Main}
                key={Math.random()}
              />
            )}
          />
          <Route
            path="/users/create"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={isFeatureEnabled ? CreateCustomer : CreateUser}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/details/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={UserDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myLearning/:tabs"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={MyLearning}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myLearning/:id/courses/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myLearning/:id/events/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={EventDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myOrders"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={MyOrders}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myOrders/:id/courses/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myOrders/:id/events/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={EventDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/myCertificates"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={MyCertificates}
                layout={Main}
              />
            )}
          />
          <Route
            path="/enrollments"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={ManageEnrollment}
                layout={Main}
              />
            )}
          />
          <Route
            path="/enrollments/:id/courses/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/enrollments/:id/events/:courseId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={EventDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/enrollments/order/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={ManageEnrollmentOrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={CustomerDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id/orders/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={CustomerOrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id/courseActivity/:productId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={CourseActivityDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id/products/:productId/orders/:orderId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={UserProductDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id/orders/:orderId/products/:productId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrderProductSeatDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/users/:id/orders/:orderId/subscription/:productId"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrderBundleSeatDetails}
                layout={Main}
              />
            )}
          />
          <Route
            path="/orders"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={Orders}
                layout={Main}
              />
            )}
          />
          <Route
            path="/orders/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={CustomerOrderDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/organizeEvents"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrganizeEvents}
                layout={Main}
              />
            )}
          />
          <Route
            path="/organizeEvents/:id"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={OrganizeEventsDetail}
                layout={Main}
              />
            )}
          />
          <Route
            path="/support"
            {...appProps}
            element={(
              <PrivateLayoutRoute
                component={supportCenter}
                layout={Main}
              />
            )}
          />
          <Route
            path="*"
            {...appProps}
            element={(
              <RouteWithLayout
                component={NotFound}
                layout={Empty}
              />
            )}
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default RootRouter;

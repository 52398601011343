import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'ErrorPage';

export const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  itemTitle: `${PREFIX}-itemTitle`,
  itemDescription: `${PREFIX}-itemDescription`,
  buttonLabel: `${PREFIX}-buttonLabel`,
  buttonRoot: `${PREFIX}-buttonRoot`,
};

export const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    fontSize: '16px',
  },

  [`& .${classes.content}`]: {
    textAlign: 'center',
  },

  [`& .${classes.image}`]: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    paddingBottom: '25px',
  },

  [`& .${classes.itemTitle}`]: {
    fontSize: '24px',
    fontWeight: '500',
  },

  [`& .${classes.itemDescription}`]: {
    lineHeight: '1.38',
    padding: '10px 0',
  },

  [`& .${classes.buttonLabel}`]: {
    textTransform: 'none',
    textDecoration: 'underline',
  },

  [`& .${classes.buttonRoot}`]: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

import { styled } from '@mui/material/styles';

const PREFIX = 'ContentCategoryViewer';

export const classes = {
  buttonLabel: `${PREFIX}-buttonLabel`,
  buttonRoot: `${PREFIX}-buttonRoot`,
  contentHeader: `${PREFIX}-contentHeader`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  tableCell: `${PREFIX}-tableCell`,
  column1: `${PREFIX}-column1`,
  column2: `${PREFIX}-column2`,
  columnHeader: `${PREFIX}-columnHeader`,
  paperContainer: `${PREFIX}-paperContainer`,
  tableContainer: `${PREFIX}-tableContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const Root = styled('div')(() => ({
  [`& .${classes.buttonLabel}`]: {
    textTransform: 'none',
    marginLeft: '8px',
  },

  [`& .${classes.buttonRoot}`]: {
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
    padding: '0px',
    minWidth: '46px',
  },

  [`& .${classes.contentHeader}`]: {
    letterSpacing: '0.15px',
  },

  [`& .${classes.dialogTitle}`]: {
    fontSize: '20px',
  },

  [`& .${classes.tableCell}`]: {
    border: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
  },

  [`& .${classes.column1}`]: {
    verticalAlign: 'top',
    letterSpacing: '0.4px',
    opacity: '0.87',
    fontWeight: 'normal',
    fontSize: '12px',
    width: '30%',
    color: '#ffffff',
  },

  [`& .${classes.column2}`]: {
    fontWeight: 'normal',
    fontSize: '12px',
    wordWrap: 'break-word',
    color: 'rgba(255, 255, 255, 0.6)',
  },

  [`& .${classes.columnHeader}`]: {
    fontSize: '14px',
  },

  [`& .${classes.paperContainer}`]: {
    width: '576px',
    maxWidth: '576px',
    padding: '15px 5px 24px 15px',
    minHeight: '375px',
    height: 'auto',
    maxHeight: '450px',
  },

  [`& .${classes.tableContainer}`]: {
    marginLeft: '-15px',
    marginTop: '-15px',
  },
}));

export default {
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: '20px 0',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        borderRadius: 2,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '@media (min-width: 600px)': {
          minWidth: 80,
        },
        textTransform: 'none',
        fontWeight: 'normal',
        padding: 6,
      },
    },
  },
};

import { useState, useEffect } from 'react';
import deepTrim from '../helper/useFormHelper';

const useForm = (callback, validate, intialFormValues = {}, comparedWithData) => {
  const [values, setvalues] = useState(intialFormValues);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    setErrors(validate(values, comparedWithData));
    setIsFormValid(Object.keys(validate(values, comparedWithData)).length === 0
    && Object.entries(touched).length);
  }, [values, validate, comparedWithData, touched]);
  const resetForm = () => {
    setvalues(intialFormValues);
    setTouched({});
    setErrors({});
    setIsFormValid(false);
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback(deepTrim(values));
  };

  const setInputValuesWithName = (name, value) => {
    setvalues((valuesPrevState) => ({
      ...valuesPrevState,
      [name]: value,
    }));
    setTouched((touchedNew) => ({
      ...touchedNew,
      [name]: true,
    }));
  };

  // TODO: Need to add logic to handle all type of inputs
  const handleInputChange = (event) => {
    event.persist();
    setInputValuesWithName(
      event.target.name,
      event.target.files ? event.target.files[0] : event.target.value,
    );
  };

  const handleInputWithBlurChange = (event) => {
    event.persist();
    setvalues((valuesPrevState) => ({
      ...valuesPrevState,
      [event.target.name]: event.target.value,
    }));
    setTouched((touchedNew) => ({
      ...touchedNew,
      [event.target.name]: false,
    }));
  };

  const handleBlur = (event) => {
    event.persist();
    setTouched((touchedNew) => ({
      ...touchedNew,
      [event.target.name]: true,
    }));
  };

  const hasError = (field) => touched[field] && errors[field] && true;

  return {
    handleSubmit,
    handleInputChange,
    setInputValuesWithName,
    values,
    touched,
    errors,
    setErrors,
    hasError,
    isFormValid,
    handleInputWithBlurChange,
    handleBlur,
    resetForm,
  };
};
export default useForm;

export default {
  h1: {
    fontWeight: 300,
    fontSize: '96px',
    lineHeight: 1.17,
    letterSpacing: '-1.5px',
    opacity: 0.87,
  },
  h2: {
    fontWeight: 300,
    fontSize: '60px',
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    opacity: 0.87,
  },
  h3: {
    fontSize: '48px',
    lineHeight: 1.17,
    opacity: 0.87,
  },
  h4: {
    fontSize: '34px',
    lineHeight: 1.06,
    opacity: 0.87,
  },
  h5: {
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: 1,
    opacity: 0.87,
  },
  h6: {
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    opacity: 0.87,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: 1.25,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: 1.5,
  },
  body1: {
    fontSize: '14px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  body2: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },
  button: {
    fontSize: '14px',
    letterSpacing: '1.25px',
  },
  caption: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: 1.33,
  },
  overline: {
    fontSize: '10px',
    letterSpacing: '1.5px',
    lineHeight: 1.6,
    textTransform: 'none',
  },
  fontFamily: 'NeoSansStd-Regular',
};

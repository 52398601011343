import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const PREFIX = 'useSnackbar';

const classes = {
  snackbar: `${PREFIX}-snackbar`,
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
};

const StyledSnackbar = styled(Snackbar)(() => ({
  [`&.${classes.snackbar}`]: {
    top: 60,
  },

  [`& .${classes.root}`]: {
    background: '#373638',
    height: '48px',
    borderRadius: '4px',
  },

  [`& .${classes.action}`]: {
    color: '#ffffff',
  },

  [`& .${classes.success}`]: {
    color: '#b0d96e',
  },

  [`& .${classes.error}`]: {
    color: '#ff7162',
  },
}));

const Alert = (props) => (<MuiAlert icon={false} elevation={8} variant="standard" {...props} />);

export default function useSnackbar() {
  const [open, setOpen] = React.useState(false);

  const [snackBarData, setSnackBarData] = React.useState({ message: '', severity: '', duration: 2000 });

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const success = async (message, duration) => {
    setSnackBarData({ message, severity: 'success', duration: duration || 3000 });
    setOpen(true);
  };

  const error = (message, duration) => {
    setSnackBarData({ message, severity: 'error', duration: duration || 3000 });
    setOpen(true);
  };

  const warning = (message) => {
    setSnackBarData({ message, severity: 'warning' });
    setOpen(true);
  };

  const getSnackBar = () => (
    <StyledSnackbar
      className={classes.snackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={snackBarData.duration}
      onClose={() => setOpen(false)}
    >
      <div>
        <Alert
          onClose={handleClose}
          classes={{
            root: classes.root,
            action: classes.action,
            message: (snackBarData.severity === 'success') ? classes.success : classes.error,
          }}
        >
          {snackBarData.message}
        </Alert>
      </div>
    </StyledSnackbar>
  );

  return {
    success,
    error,
    warning,
    getSnackBar,
  };
}

import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { t } from '@lingui/macro';
import {
  Button, List, ListItem, ListItemIcon, Typography,
} from '@mui/material';
import { keys, isEmpty } from 'lodash';
import ClientDetails from '@mui/icons-material/BusinessOutlined';
import Downloads from '@mui/icons-material/GetApp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ClassIcon from '@mui/icons-material/Class';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UserLsitingIcon from '@mui/icons-material/PeopleAlt';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { ReactComponent as CheckCircleOutlineIcon } from '../../../../../../assets/icons/task_alt_white_24dp.svg';
import Can from '../../../../../../components/Can';
import { ReactComponent as Zosi } from '../../../../../../assets/images/zosi.svg';
import { AS_USER_ID } from '../../../../../../constants/appConstants';
import { Root, classes } from './styles';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = ({
  disableMenu,
}) => {
  const location = useLocation();
  const isViewAsUserEnabled = localStorage.getItem(AS_USER_ID);
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');

  let pages = {
    'Alchemy Express': {
      title: t`Alchemy Express`,
      icon: <Zosi />,
      viewAsCallerUser: true,
      permissions: ['read:client_libraries'],
      children: [
        {
          title: t`Downloads`,
          href: '/downloads',
          viewAsCallerUser: true,
          icon: <Downloads />,
          permissions: ['read:client_libraries'],
        },
        {
          title: t`Company Details`,
          href: '/clientDetails',
          viewAsCallerUser: true,
          icon: <ClientDetails />,
          permissions: ['read:client_clients'],
        },
        {
          title: t`Users`,
          href: '/users',
          viewAsCallerUser: true,
          icon: <UserLsitingIcon />,
          permissions: ['read:client_users'],
        },
      ],
    },
  };

  const learning = {
    'My Learning': {
      title: t`My Learning`,
      href: '/myLearning',
      icon: <LibraryBooksIcon />,
      permissions: [],
      viewAsCallerUser: true,
      children: [],
    },
    'My Orders': {
      title: t`My Orders`,
      href: '/myOrders',
      icon: <ListAltIcon />,
      permissions: [],
      viewAsCallerUser: true,
      children: [],
    },
    'My Certificates': {
      title: t`My Certificates`,
      href: '/myCertificates',
      icon: <ClassIcon />,
      permissions: [],
      viewAsCallerUser: true,
      children: [],
    },
    'Manage Enrollments': {
      title: t`Manage Enrollments`,
      href: '/enrollments',
      icon: <CheckCircleOutlineIcon />,
      permissions: [],
      viewAsCallerUser: true,
      children: [],
    },
    Users: {
      title: t`Users`,
      href: '/users',
      icon: <PersonSharpIcon />,
      permissions: ['read:zosi_users'],
      viewAsCallerUser: !isViewAsUserEnabled,
      children: [],
      divider: true,
    },
    Orders: {
      title: t`Orders`,
      href: '/orders',
      icon: <ListOutlinedIcon />,
      permissions: ['read:zosi_orders'],
      viewAsCallerUser: !isViewAsUserEnabled,
      children: [],
    },
    Organizers: {
      title: t`Organize Events`,
      href: '/organizeEvents',
      icon: <ListAltIcon />,
      permissions: ['read:organized_events'],
      viewAsCallerUser: !isViewAsUserEnabled,
      children: [],
      divider: true,
    },
  };
  if (isFeatureEnabled) {
    pages = { ...learning };
  }
  const pageNames = keys(pages);
  const handleClick = () => {
    setOpen(!open);
  };
  const isActive = (page) => {
    let activePage = [];
    activePage = page.children.filter((ele) => location.pathname.includes(ele.href));
    return !isEmpty(activePage);
  };

  const getButton = (page) => (
    <Button
      activeclassname={!disableMenu && classes.active}
      classes={{ root: classes.button, disabled: classes.buttonDisabled }}
      className={`${page.divider && classes.refundMenu} ${`/${location.pathname.split('/')[1]}` === page.href && classes.active}`}
      component={CustomRouterLink}
      disabled={disableMenu}
      to={page.href}
      color="secondary"
    >
      <ListItemIcon
        classes={{ root: classes.icon }}
        className={clsx({
          [classes.iconDisabled]: disableMenu,
        })}
      >
        {page.icon}
      </ListItemIcon>
      <span>
        <Typography variant="caption">
          {page.title}
        </Typography>
      </span>
    </Button>
  );
  const renderList = (page, className) => (
    <Can
      permissions={page.permissions}
      user={user}
      yes={() => page.viewAsCallerUser && (
        <ListItem
          className={clsx(classes.item, {
            [classes.nested]: className,
          })}
          key={page.title}
        >
          {getButton(page)}
        </ListItem>
      )}
    />
  );
  return (
    (
      <Root>
        <List className={classes.root} disabled={disableMenu}>
          {pageNames.map((page) => (
            (isEmpty(pages[page].children) ? (
              renderList(pages[page])
            )
              : (
                <>
                  <Can
                    permissions={pages[page].permissions}
                    user={user}
                    yes={() => (
                      <>
                        <ListItem
                          className={clsx(classes.item, {
                            [classes.activeParent]: (!disableMenu && isActive(pages[page])),
                            [classes.buttonDisabled]: !isActive(pages[page]),
                          })}
                          key={pages[page].title}
                          disabled={disableMenu}
                          onClick={handleClick}
                        >
                          <Button
                            disabled={disableMenu}
                            className={classes.textBackground}
                          >
                            <ListItemIcon
                              classes={{ root: classes.icon }}
                              className={clsx({
                                [classes.iconDisabled]: disableMenu,
                              })}
                            >
                              {pages[page].icon}
                            </ListItemIcon>
                            <span>
                              <Typography variant="caption" classes={{ root: classes.tab }}>
                                {pages[page].title}
                              </Typography>
                            </span>
                          </Button>
                          {open
                            ? (
                              <ExpandLess className={clsx({
                                [classes.iconDisabled]: disableMenu,
                              })}
                              />
                            )
                            : (
                              <ExpandMore className={clsx({
                                [classes.iconDisabled]: disableMenu,
                              })}
                              />
                            )}
                        </ListItem>
                        <Collapse in={open} timeout="auto">
                          <List>
                            {pages[page].children.map((child) => (
                              renderList(child, 'nested')
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                  />
                </>
              )
            )
          ))}
        </List>
        <div style={{ flexGrow: 1 }} />
      </Root>
    )
  );
};

SidebarNav.propTypes = {
  disableMenu: PropTypes.bool.isRequired,
};

export default SidebarNav;

export default {
  styleOverrides: {
    listbox: {
      width: '100%',
      margin: '2px 0 0',
      padding: 0,
      position: 'bottom-start',
      listStyle: 'none',
      overflow: 'auto',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 1,
      maxHeight: '15rem',
    },
    option: {
      padding: '5px 12px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        flexGrow: 1,
      },
      ' & svg': {
        color: 'transparent',
      },
      '&[aria-selected=true]': {
        '& svg': {
          color: '#ffc700',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
      },
      '&[data-focus=true]': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
        '& svg': {
          color: '#fff',
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
        '& svg': {
          color: '#fff',
        },
      },
      '&[aria-disabled=true]': {
        backgroundColor: '#383838',
        '& svg': {
          color: '#ffc700',
        },
      },
    },
  },
};

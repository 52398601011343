import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { changePassword } from '../../api/users';
import { resetPasswordHistoryLog } from '../../api/history';

const ChangePasswordDialog = ({
  open, setOpen,
}) => {
  const {
    user: {
      email,
      sub: userId,
    },
  } = useAuth0();

  const { data: { data } = {}, isLoading, error } = useQuery(['changePassword', email], () => changePassword(email), { suspense: false });

  useEffect(() => {
    if (!isLoading && !error) {
      const userData = {
        logEvent: 'request_reset_password',
        entity: 'users',
        entityKey: userId,
      };
      resetPasswordHistoryLog(userData);
    }
  }, [isLoading, error, userId]);
  return (
    <Dialog
      open={(!isLoading && open)}
      keepMounted
      aria-labelledby="alert-dialog"
      PaperProps={{
        variant: 'outlined',
      }}
    >
      <DialogContent>
        <DialogContentText>
          {!isLoading && (!error ? data : t`Something went wrong, Please try later`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          color="primary"
        >
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangePasswordDialog.defaultProps = {
  open: false,
  setOpen: () => {},
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default ChangePasswordDialog;

import { Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'index';

export const classes = {
  backdrop: `${PREFIX}-backdrop`,
  circularProgress: `${PREFIX}-circularProgress`,
};

export const Root = styled('div')(() => ({
  [`&.${classes.circularProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

export const StyledBackdrop = styled(Backdrop)(() => ({
  [`&.${classes.backdrop}`]: {
    zIndex: 1500,
    color: '#fff',
  },
}));

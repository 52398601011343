import getProdTheme from './prodTheme';
import getDevTheme from './devTheme';

const getThemeBasedOnEnv = (isDarktheme) => {
  const currentEnv = process.env.REACT_APP_LAYOUT?.toUpperCase();
  switch (currentEnv) {
  case 'DEV':
    return getDevTheme(isDarktheme);
  case 'PROD':
    return getProdTheme(isDarktheme);
  default:
    return getProdTheme(isDarktheme);
  }
};

export default getThemeBasedOnEnv;

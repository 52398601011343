import { styled } from '@mui/material';

const PREFIX = 'Minimal';

export const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  devText: `${PREFIX}-devText`,
};

export const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    height: '100%',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    minHeight: '86vh',
  },

  [`& .${classes.devText}`]: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));

import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { I18nProvider, useLingui } from '@lingui/react';
import { dynamicActivate, defaultLocale } from '../config/i18n';

const WatchLocale = ({ children }) => {
  const { i18n: lingui } = useLingui();
  // Skip render when locale isn't loaded
  if (!lingui.locale) {
    return null;
  }
  // Force re-render when locale changes.
  // Otherwise string translations (ie: t`Macro`) won't be updated.
  return <Fragment key={lingui.locale}>{children}</Fragment>;
};

const AppI18nProvider = ({ children }) => {
  useEffect(() => {
    dynamicActivate(defaultLocale);
  }, []);
  return (
    <I18nProvider i18n={i18n}>
      <WatchLocale>
        {children}
      </WatchLocale>
    </I18nProvider>
  );
};

AppI18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
WatchLocale.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppI18nProvider;

export default {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
      },
      '&.Mui-selected:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
      },
      '& :hover': {
        backgroundColor: '#2e2e2e',
      },
    },
  },
};

import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import Sidebar from './components/Sidebar/Sidebar';
import Topbar from '../commonComponents/Topbar/Topbar';
import Footer from '../commonComponents/Footer/AppFooter';
import useAppContext from '../../hooks/useAppContext';

const PREFIX = 'Main';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  devText: `${PREFIX}-devText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    paddingTop: 56,
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minHeight: 'calc(100vh - 130px)',
    padding: '20px',
    paddingBottom: '0px',
  },

  [`& .${classes.devText}`]: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));

const Main = (props) => {
  const { children, disableMenu, hideTopBarClient } = props;

  const { currentEnv } = useAppContext();
  return (
    (
      <>
        <Root
          className={classes.root}
        >
          <Topbar hideTopBarClient={hideTopBarClient} />
          <Sidebar
            open
            disableMenu={disableMenu}
            variant="permanent"
          />
          <main className={classes.content}>
            {children}
            {currentEnv === 'DEV' && <footer className={classes.devText}><Trans>DEV ENV</Trans></footer>}
          </main>
        </Root>
        <Footer />
      </>
    )
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  hideTopBarClient: PropTypes.bool.isRequired,
};

export default Main;

import { styled } from '@mui/material/styles';

const PREFIX = 'Minimal';

export const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  devText: `${PREFIX}-devText`,
};

export const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },

  [`& .${classes.content}`]: {
    height: '100%',
  },

  [`& .${classes.devText}`]: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));

import { styled } from '@mui/material/styles';

const PREFIX = 'ClientMenu';

export const classes = {
  root: `${PREFIX}-root`,
  menuItem: `${PREFIX}-menuItem`,
  menuListContainer: `${PREFIX}-menuListContainer`,
  topBarHeader: `${PREFIX}-topBarHeader`,
};

export const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    marginLeft: theme.spacing(3.25),
  },

  [`& .${classes.menuItem}`]: {
    fontSize: theme.spacing(1.75),
    fontWeight: 'normal',
    backgroundColor: '#353535',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },

  [`& .${classes.menuListContainer}`]: {
    '& .MuiMenuItem-divider': {
      borderTop: '1px solid rgba(255, 255, 255, 0.31)',
      borderBottom: 'none',
    },
  },

  [`& .${classes.topBarHeader}`]: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#353535',
    },
  },
}));

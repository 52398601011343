import React from 'react';
import { useQuery } from 'react-query';
import useAppContext, { AppContext } from './hooks/useAppContext';
import Routes from './Routes';
import { getFeatureFlagsAPI } from './api/featureFlags';

const RootContainer = () => {
  const { data: { data: contentData = [] } = {} } = useQuery(['featureToggles'], () => getFeatureFlagsAPI(), {
    suspense: false,
  });
  const prevContexts = useAppContext();
  return (
    <AppContext.Provider
      value={{
        ...prevContexts,
        featureFlags: contentData,
      }}
    >
      <Routes />

    </AppContext.Provider>
  );
};

export default RootContainer;

import { styled } from '@mui/material';

const PREFIX = 'SidebarNav';

export const classes = {
  hide: `${PREFIX}-hide`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  copy: `${PREFIX}-copy`,
  footer: `${PREFIX}-footer`,
  root: `${PREFIX}-root`,
  iconButton: `${PREFIX}-iconButton`,
  toggleIcon: `${PREFIX}-toggleIcon`,
  toggleFooter: `${PREFIX}-toggleFooter`,
  drawerFooter: `${PREFIX}-drawerFooter`,
  item: `${PREFIX}-item`,
  footerText: `${PREFIX}-footerText`,
  button: `${PREFIX}-button`,
  buttonDisabled: `${PREFIX}-buttonDisabled`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  active: `${PREFIX}-active`,
  nested: `${PREFIX}-nested`,
  activeParent: `${PREFIX}-activeParent`,
  iconDisabled: `${PREFIX}-iconDisabled`,
  refundMenu: `${PREFIX}-refundMenu`,
  tab: `${PREFIX}-tab`,
  textBackground: `${PREFIX}-textBackground`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.hide}`]: {
    display: 'none',
  },

  [`& .${classes.drawerOpen}`]: {
    width: '150px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },

  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      paddingTop: 64,
    },
  },

  [`& .${classes.copy}`]: {
    padding: '4px',
  },

  [`& .${classes.footer}`]: {
    opacity: '0.4',
  },

  [`& .${classes.root}`]: {
    paddingTop: '20px',
    paddingBottom: '11px',
  },

  [`& .${classes.iconButton}`]: {
    background: theme.palette.disabledIcon,
    color: theme.palette.disabledIconColor,
    '&:hover': {
      background: theme.palette.disabledIcon,
    },
  },

  [`& .${classes.toggleIcon}`]: {
    justifyContent: 'flex-end',
    padding: '5px',
  },

  [`& .${classes.toggleFooter}`]: {
    justifyContent: 'center',
    padding: '5px',
  },

  [`& .${classes.drawerFooter}`]: {
    display: 'block',
    top: '30%',
    cursor: 'pointer',
  },

  [`& .${classes.item}`]: {
    padding: 0,
  },

  [`& .${classes.footerText}`]: {
    textAlign: 'center',
    marginLeft: '3px',
    fontSize: '18px',
  },

  [`& .${classes.button}`]: {
    width: '100%',
    color: theme.palette.text.primary,
    borderRadius: 0,
    opacity: '0.38',
    borderRight: '6px solid transparent',
    padding: '10px 0 10px 10px',
    justifyContent: 'flex-start',
  },

  [`& .${classes.buttonDisabled}`]: {
    color: theme.palette.text.primary,
    opacity: '0.38',
  },

  [`& .${classes.icon}`]: {
    marginRight: '10px',
    minWidth: '0',
    color: theme.palette.icon,
  },

  [`& .${classes.text}`]: {
    fontSize: '12px',
    fontWeight: 400,
  },

  [`& .${classes.active}`]: {
    borderRight: `6px solid ${theme.palette.secondary.main}`,
    background: theme.palette.background.container,
    opacity: '1',
  },

  [`& .${classes.nested}`]: {
    '& a': {
      paddingLeft: '25px',
    },
  },

  [`& .${classes.activeParent}`]: {
    background: theme.palette.background.container,
    opacity: '1',
  },

  [`& .${classes.iconDisabled}`]: {
    opacity: '0.38',
  },

  [`& .${classes.refundMenu}`]: {
    borderTop: '1px solid rgba(255, 255, 255, 0.65)',
  },

  [`& .${classes.tab}`]: {
    color: theme.palette.white,
  },

  [`& .${classes.textBackground}`]: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

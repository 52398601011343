import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import AppFeatureToggleProvider from './AppFeatureToggleProvider';
import AppAuthProvider from './AppAuthProvider';
import AppI18nProvider from './AppI18nProvider';
import AppThemeProvider from './AppThemeProvider';
import AppContextProvider from './AppContextProvider';
import AppReactQueryProvider from './AppReactQueryProvider';
import AppSnackbarProvider from './AppSnackbarProvider';

const AppProvider = ({ children }) => (
  <BrowserRouter>
    <AppReactQueryProvider>
      <AppContextProvider>
        <AppFeatureToggleProvider>
          <AppAuthProvider>
            <AppI18nProvider>
              <AppThemeProvider>
                <AppSnackbarProvider>
                  {children}
                </AppSnackbarProvider>
              </AppThemeProvider>
            </AppI18nProvider>
          </AppAuthProvider>
        </AppFeatureToggleProvider>
      </AppContextProvider>
    </AppReactQueryProvider>
  </BrowserRouter>
);
AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppProvider;

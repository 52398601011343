import { styled } from '@mui/material/styles';

const PREFIX = 'UserProductInformation';

export const classes = {
  infoArea: `${PREFIX}-infoArea`,
  container: `${PREFIX}-container`,
  dataItemLabel: `${PREFIX}-dataItemLabel`,
  datePicker: `${PREFIX}-datePicker`,
  subscriptionDate: `${PREFIX}-subscriptionDate`,
  infoLabel: `${PREFIX}-infoLabel`,
  warningText: `${PREFIX}-warningText`,
  textWrap: `${PREFIX}-textWrap`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.infoArea}`]: {
    marginBottom: '15px',
  },

  [`& .${classes.container}`]: {
    padding: '0px 8px 0px 8px',
  },

  [`& .${classes.dataItemLabel}`]: {
    opacity: '0.6',
  },

  [`& .${classes.datePicker}`]: {
    '& input': {
      paddingBottom: '5px',
      paddingTop: '10px',
    },
    '& button': {
      padding: '0px',
    },
  },

  [`& .${classes.subscriptionDate}`]: {
    display: 'inline-grid',
  },

  [`& .${classes.infoLabel}`]: {
    marginBlock: '10px',
  },

  [`& .${classes.warningText}`]: {
    whiteSpace: 'nowrap',
    color: theme.palette.error.main,
  },

  [`& .${classes.textWrap}`]: {
    whiteSpace: 'nowrap',
  },
}));

const white = '#FFFFFF';
const black = '#000000';

export default {
  mode: 'dark',
  primary: {
    contrastText: black,
    main: '#ffc700',
  },
  secondary: {
    contrastText: black,
    main: '#21b6d7',
  },
  success: {
    contrastText: black,
    main: '#b0d96e',
  },
  error: {
    contrastText: black,
    main: '#FF7162',
  },
  background: {
    default: '#1d1d1d',
    paper: '#2e2e2e',
    container: '#252525',
  },
  disabledIcon: 'rgba(255,255,255,0.6)',
  outlineBorder: 'rgba(255,255,255,0.23)',
  selectedOptionBgColor: 'rgba(255, 255, 255, 0.16)',
  disabledIconColor: '#383838',
  white,
};

import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

import { t } from '@lingui/macro';

const PREFIX = 'DropDownSelector';

const classes = {
  text: `${PREFIX}-text`,
  popup: `${PREFIX}-popup`,
  noPadding: `${PREFIX}-noPadding`,
  tagStyle: `${PREFIX}-tagStyle`,
  alignText: `${PREFIX}-alignText`,
};

const Root = styled('div')((
  { theme, marginBottom },
) => ({
  [`& .${classes.text}`]: {
    marginBottom,
  },

  [`& .${classes.popup}`]: {
    backgroundColor: '#363636',
    boxShadow: '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.2)',
  },

  [`& .${classes.noPadding}`]: {
    padding: '0px',
  },

  [`& .${classes.tagStyle}`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    height: '20px',
    margin: '2px',
    lineHeight: '22px',
    backgroundColor: theme.palette.disabledIconColor,
    border: `1px solid ${theme.palette.disabledIconColor}`,
    borderRadius: '2px',
    boxSizing: 'content-box',
    padding: '0 4px 0 10px',
    outline: 0,
    overflow: 'hidden',

    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& svg': {
      fontSize: '15px',
      cursor: 'pointer',
      padding: '10px',
    },
  },

  [`& .${classes.alignText}`]: {
    textAlign: 'right',
  },
}));

export default function DropDownSelector({
  value,
  handleChange,
  isError,
  multiple,
  size,
  inputClasses,
  errorMessage,
  fieldName,
  optionList,
  handleBlur,
  inputlabel,
  noOptionsText,
  disabled,
  inputRef,
  disableClearable,
  displayLabel,
  freeSolo,
  marginBottom,
}) {
  const renderOption = (props, option) => (
    <>
      <li {...props} data-testid={option.dataTestid}>
        <span data-testid={option.dataTestid}>{option.displayName || option.name}</span>
        {!option.disabledCheckIcon && <CheckIcon fontSize="small" />}
      </li>
    </>
  );

  const getTag = (label, onDelete) => (
    <div key={label} className={classes.tagStyle}>
      <span data-testid="autoCompleteTag">{label}</span>
      <IconButton aria-label="delete" size="small" disabled={disabled} onClick={onDelete} className={classes.noPadding}>
        <CloseIcon />
      </IconButton>
    </div>
  );

  const renderTag = (tagValue, getTagProps) => tagValue.map((option, index) => (
    getTag(option.code, getTagProps({ index }).onDelete)
  ));

  const renderInput = (params) => (
    <TextField
      fullWidth
      error={isError}
      helperText={isError && errorMessage}
      name={fieldName}
      label={inputlabel}
      variant="filled"
      {...params}
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        classes: { root: inputClasses },
      }}
    />
  );

  return (
    <Root marginBottom={marginBottom}>
      <Autocomplete
        autoComplete
        multiple={multiple}
        classes={{
          input: classes.text,
        }}
        size={size}
        disabled={disabled}
        limitTags={5}
        id={`${inputlabel}-selector`}
        noOptionsText={noOptionsText}
        value={(multiple && !value) ? [] : value}
        onChange={(event, newValue) => handleChange(newValue, event)}
        onBlur={handleBlur}
        options={optionList}
        isOptionEqualToValue={(option, selectedValue) => (
          option.code === selectedValue.code
        && option.id === selectedValue.id
        )}
        getOptionDisabled={(option) => option.disabled}
        getOptionLabel={(option) => displayLabel(option)}
        renderOption={renderOption}
        renderTags={renderTag}
        renderInput={renderInput}
        data-testid="autoComplete"
        disableClearable={disableClearable}
        freeSolo={freeSolo}
      />
    </Root>

  );
}
DropDownSelector.defaultProps = {
  errorMessage: '',
  size: 'small',
  isError: false,
  value: null,
  inputClasses: null,
  multiple: false,
  noOptionsText: t`No options available`,
  disabled: false,
  handleChange: null,
  inputRef: null,
  disableClearable: false,
  freeSolo: false,
  marginBottom: '7px',
  handleBlur: null,
};

DropDownSelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({ code: PropTypes.string, id: PropTypes.number }),
    PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }))]),
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  size: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  inputlabel: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  noOptionsText: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  disableClearable: PropTypes.bool,
  displayLabel: PropTypes.func.isRequired,
  freeSolo: PropTypes.bool,
  marginBottom: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { i18n } from '@lingui/core';

const getLanguages = () => ({
  data: [
    { display: 'US - English', code: 'en-US', locale: 'en' },
    { display: 'US - Spanish', code: 'es-MX', locale: 'es' },
    { display: 'BR - Portuguese', code: 'pt-BR', locale: 'pt-BR' },
    { display: 'DE - German', code: 'de-DE', locale: 'de' },
    { display: 'FR - French', code: 'fr-FR', locale: 'fr' },
  ],
});
const AppAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const getAuth0Locale = (code) => getLanguages().data.find((o) => o.code === code);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        ui_locales: getAuth0Locale(i18n.locale),
      }}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};
AppAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppAuthProvider;

// https://github.com/auth0/auth0-spa-js/blob/main/MIGRATION_GUIDE.md#no-more-iframe-fallback-by-default-when-using-refresh-tokens

import React from 'react';
import PropTypes from 'prop-types';
import { ConfigureFlopFlip } from '@flopflip/react-broadcast';

import adapter from '@flopflip/memory-adapter';

const AppFeatureToggleProvider = ({ children }) => {
  let defaultFlags = {};
  try {
    defaultFlags = JSON.parse(process.env.REACT_APP_FEATURE_TOGGLES);
  } catch (error) {
    throw new Error('"REACT_APP_FEATURE_TOGGLES" must contain a json parsable string');
  }
  return (
    <ConfigureFlopFlip adapter={adapter} adapterArgs={{}} defaultFlags={defaultFlags}>
      {children}
    </ConfigureFlopFlip>
  );
};
AppFeatureToggleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppFeatureToggleProvider;

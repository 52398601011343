import React from 'react';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { Link, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { t, Trans } from '@lingui/macro';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import poweredByIntertekLogo from '../../../assets/images/poweredByIntertek-logo.svg';

const PREFIX = 'AppFooter';

const classes = {
  root: `${PREFIX}-root`,
  footerText: `${PREFIX}-footerText`,
  white: `${PREFIX}-white`,
  footerTitle: `${PREFIX}-footerTitle`,
  footer: `${PREFIX}-footer`,
  logo: `${PREFIX}-logo`,
  socialMedia: `${PREFIX}-socialMedia`,
  policy: `${PREFIX}-policy`,
  helpIcon: `${PREFIX}-helpIcon`,
  mailCenter: `${PREFIX}-mailCenter`,
  iconSize: `${PREFIX}-iconSize`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: `${theme.palette.background.default}`,
    borderTop: `solid 1px ${theme.palette.background.paper}`,
    overflowX: 'hidden',
    bottom: '0px',
    left: '0px',
    width: '100%',
    right: '0px',
    marginBottom: '0px',
    position: 'fixed',
  },

  [`& .${classes.footerText}`]: {
    fontSize: '14px',
    lineHeight: 1.5,
  },

  [`& .${classes.white}`]: {
    color: theme.palette.white,
  },

  [`& .${classes.footerTitle}`]: {
    fontSize: '12px',
    color: theme.palette.disabledIcon,
  },

  [`& .${classes.footer}`]: {
    paddingTop: '10px',
  },

  [`& .${classes.logo}`]: {
    marginLeft: '20px',
  },

  [`& .${classes.socialMedia}`]: {
    padding: '10px',
    fontSize: 12,
  },

  [`& .${classes.policy}`]: {
    marginTop: '2px',
    fontSize: 12,
  },

  [`& .${classes.helpIcon}`]: {
    borderRight: '1px solid white',
    paddingRight: '5px',
    color: theme.palette.white,
  },

  [`& .${classes.mailCenter}`]: {
    fontSize: 12,
    marginTop: '3px',
  },
}));

export default function AppFooter() {
  const { REACT_APP_WP_URL } = process.env;
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');

  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  return (isAuthenticated || location.pathname === '/CertificateVerification') && (
    <Root className={classes.root}>
      <footer>
        <Grid
          container
          spacing={0}
          justifyContent="space-between"
          classes={{
            root: classes.footer,
          }}
        >
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="column"
              classes={{
                root: classes.logo,
              }}
            >
              <Grid item>
                <img
                  alt="Logo"
                  src={poweredByIntertekLogo}
                  height="30"
                  width="115"
                />
              </Grid>
              <Grid item>
                <Typography className={classes.footerTitle}>
                  &copy;
                  {' '}
                  {t`Alchemy Zosi ${new Date().getFullYear().toString()}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="column"
              justifyContent="center"
            >
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  classes={{
                    root: classes.policy,
                  }}
                >
                  <Grid item>
                    <Link
                      component="a"
                      href={`${REACT_APP_WP_URL}terms-of-use/`}
                      target="_blank"
                      className={classes.helpIcon}
                    >
                      <Trans>Terms of Use</Trans>
                    </Link>
                  </Grid>
                  {isFeatureEnabled && (
                    <Grid item>
                      <Link
                        component="a"
                        href={`${REACT_APP_WP_URL}support/`}
                        target="_blank"
                        className={classes.helpIcon}
                      >
                        <Trans>FAQ</Trans>
                      </Link>
                    </Grid>
                  )}
                  <Grid item>
                    <Link
                      component="a"
                      href={`${REACT_APP_WP_URL}privacy-policy/`}
                      target="_blank"
                      className={classes.helpIcon}
                    >
                      <Trans>Privacy Policy</Trans>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href={`${REACT_APP_WP_URL}support/`}
                      target="_blank"
                      className={classes.white}
                    >
                      <Trans>Support Center</Trans>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  classes={{
                    root: classes.mailCenter,
                  }}
                >
                  <Grid item>
                    <MailIcon
                      fontSize="inherit"
                    />
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="mailto: support@zosilearning.com"
                      target="_blank"
                      className={classes.white}
                    >
                      <Trans>Alchemy Support</Trans>
                    </Link>
                  </Grid>
                  <Grid item>
                    <WatchLaterIcon
                      fontSize="inherit"
                    />
                  </Grid>
                  <Grid item>
                    <Trans> Hours of Operation: Monday – Friday, 5 AM – 7 PM CT</Trans>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              classes={{
                root: classes.socialMedia,
              }}
            >
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.facebook.com/ZosiLearning"
                    target="_blank"
                    className={classes.white}
                  >
                    <FacebookIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://twitter.com/ZosiLearning"
                    target="_blank"
                    className={classes.white}
                  >
                    <TwitterIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.linkedin.com/company/zosilearning"
                    target="_blank"
                    className={classes.white}
                  > <LinkedInIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.youtube.com/channel/UCkxNPHAX8S_uqoSDNs2_jEw"
                    target="_blank"
                    className={classes.white}
                  >   <YouTubeIcon />
                  </Link>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </Root>
  );
}

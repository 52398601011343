import { useMutation } from 'react-query';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import useAppContext from './useAppContext';
import useSetLoader from './useSetLoader';
import {
  AS_USER_NAME, AS_USER_ID, AS_USER_SESSION, AS_USER_PATH_NAME,
} from '../constants/appConstants';
import { userActivityLog } from '../api/customers';

const useUserActivityLog = (userId, setUserWarning) => {
  const navigate = useNavigate();
  const { error: notificationsError } = useAppContext();
  const pathName = localStorage.getItem(AS_USER_PATH_NAME);
  const {
    mutate: mutateUserActivityLog,
    isLoading: checkContentStatusLoading,
  } = useMutation(userActivityLog, {
    onSuccess: () => {
      localStorage.removeItem(AS_USER_ID);
      localStorage.removeItem(AS_USER_SESSION);
      localStorage.removeItem(AS_USER_NAME);
      localStorage.removeItem(AS_USER_PATH_NAME);
      if (pathName && ['/courseActivity', '/products'].some((subStr) => pathName.includes(subStr))) {
        navigate(pathName);
      } else {
        navigate(`/users/${userId}`);
      }
      setUserWarning(false);
    },
    onError: () => {
      notificationsError(t`Failed to exit user activity`);
      setUserWarning(false);
    },
  });
  useSetLoader('User Activity', checkContentStatusLoading);
  return {
    mutateUserActivityLog,
  };
};

export default useUserActivityLog;

import moment from 'moment/moment';

const deepTrim = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    const value = newObj[key];
    const type = typeof value;
    if (value != null && (type === 'string' || type === 'object')) {
      if (Array.isArray(value)
      || value instanceof Blob
      || value instanceof moment
      || value instanceof Date) {
        newObj[key] = value;
      } else if (type === 'object') {
        newObj[key] = deepTrim(newObj[key]);
      } else {
        newObj[key] = newObj[key].trim();
      }
    }
  });
  return newObj;
};

export default deepTrim;

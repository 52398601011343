import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import Loading from './components/Loading';
import apiInterceptor from './interceptor/apiInterceptor';
import {
  IS_PROFILE_FULFILLED, SESSION_VARIABLE_USER_ACTION, SESSION_VARIABLE, PREFERRED_LANG,
} from './constants/appConstants';
import RootContainer from './RootContainer';
import LoadingIndicator from './components/LoadingIndicator';
import { NAMESPACE } from './constants/apiConstants';
import usePendo from './hooks/usePendo';
import useExpress from './hooks/useExpress';
import { dynamicActivate } from './config/i18n';
import localeFormat from './helper/languageHelper';

const App = () => {
  usePendo();
  useExpress();
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const {
    getAccessTokenSilently, isAuthenticated, logout, user, isLoading,
  } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const { REACT_APP_AUTH0_AUDIENCE } = process.env;
      getAccessTokenSilently({
        audience: REACT_APP_AUTH0_AUDIENCE,
      }).catch(() => {}).finally(() => setIsAuthenticating(false));
    } else {
      setIsAuthenticating(false);
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, setIsAuthenticating]);
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');
  if (isLoading || isAuthenticating) return <Loading isLoading withBackDrop={false} />;
  if (user) {
    const isProfileFulfilled = localStorage.getItem(IS_PROFILE_FULFILLED);
    if (!isProfileFulfilled) localStorage.setItem(IS_PROFILE_FULFILLED, '');
    const userPreferredLang = user[`${NAMESPACE}user_metadata`]?.language;
    const preferredLang = userPreferredLang?.code;
    const islangAvailable = JSON.parse(localStorage.getItem(PREFERRED_LANG))?.code;
    if (preferredLang && !islangAvailable) {
      localStorage.setItem(PREFERRED_LANG, JSON.stringify(userPreferredLang));
      dynamicActivate(localeFormat(preferredLang));
    } else if (islangAvailable) {
      dynamicActivate(localeFormat(islangAvailable));
    } else if (!preferredLang && !islangAvailable) {
      localStorage.setItem(PREFERRED_LANG, JSON.stringify({ code: 'EN-US', name: 'US - English' }));
    }
    if ((location.pathname === '/downloads/libraries' && isFeatureEnabled)) {
      navigate('/myLearning');
    }
  }
  apiInterceptor(getAccessTokenSilently, isAuthenticated, logout);
  localStorage.setItem(SESSION_VARIABLE, 'USER');
  localStorage.setItem(SESSION_VARIABLE_USER_ACTION, new Date());
  return (
    <>
      <LoadingIndicator />
      <RootContainer />
    </>
  );
};
export default App;

import { useEffect } from 'react';
import memoryAdapter from '@flopflip/memory-adapter';

export default function useExpress() {
  const rootSite = window.location.hostname;

  useEffect(() => {
    if (rootSite.includes('express.zosilearning.com')) {
      memoryAdapter.updateFlags({ enableLearnerMode: false });
    } else {
      memoryAdapter.updateFlags({ enableLearnerMode: true });
    }
    if (rootSite.includes('prod')) {
      memoryAdapter.updateFlags({ enableProdMode: true });
    } else {
      memoryAdapter.updateFlags({ enableProdMode: false });
    }
  }, [rootSite]);
}

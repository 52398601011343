import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { classes, Root, StyledBackdrop } from './styles';

export default function Loading({ isLoading, withBackDrop }) {
  return withBackDrop ? (
    <StyledBackdrop open={isLoading} className={classes.backdrop} data-testid="LoadingIndicator">
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  ) : (
    <Root className={classes.circularProgress}><CircularProgress color="inherit" /></Root>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  withBackDrop: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
  withBackDrop: true,
};

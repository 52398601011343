import { NAMESPACE } from '../constants/apiConstants';

export const getCompanies = (user) => {
  const { companies = [] } = user[`${NAMESPACE}user_metadata`];
  return companies;
};

export const checkPermissions = (user, permissions) => {
  const userPermissions = user[`${NAMESPACE}permissions`]?.map(({ permission_name: name }) => name) || [];
  return permissions.some((permission) => userPermissions.includes(permission));
};

import { Drawer, styled } from '@mui/material';

const drawerWidth = 180;
const PREFIX = 'Sidebar';

export const classes = {
  drawer: `${PREFIX}-drawer`,
  paper: `${PREFIX}-paper`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
};

export const StyledDrawer = styled(Drawer)((
  {
    theme,
  },
) => ({
  [`&.${classes.drawer}`]: {
    flexShrink: 0,
    width: drawerWidth,
  },

  [`& .${classes.paper}`]: {
    position: 'static',
  },

  [`&.${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: `${theme.palette.background.paper}`,
  },

  [`&.${classes.drawerClose}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '50px',
    },
  },
}));

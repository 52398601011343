import axios from 'axios';

import {
  AUTH0_ADMIN_RESET_USER_PASSWORD,
  CONNECTION,
} from '../constants/apiConstants';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_API_GATEWAY_URL,
} = process.env;

export const checkUserStatus = async ({ email, userId }) => axios.post(
    `${process.env.REACT_APP_API_GATEWAY_URL}/users/status`,
    { emailId: email, userId },
    { headers: { scope: 'read:zosi_users' } },
);

export const changePassword = async (email) => {
  const options = {
    method: 'POST',
    url: `https://${REACT_APP_AUTH0_DOMAIN}${AUTH0_ADMIN_RESET_USER_PASSWORD}`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: CONNECTION,
    },
  };
  return axios.request(options);
};

export const manuallyChangePassword = async ({ id, data }) => axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/users/${id}/change-password`, data, {
  headers: { scope: 'update:zosi_users' },
});

export const createUser = async ({ data }) => {
  const phoneNumber = data.phoneNumber && data.phoneNumber.length > 1 && data.phoneNumber;
  const newUser = {
    ...data,
    phoneNumber,
  };
  return axios.post(
    `${process.env.REACT_APP_API_GATEWAY_URL}/company/users`,
    newUser,
    { headers: { scope: 'create:client_users' } },
  );
};
export const createClientUser = async ({ data }) => axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/users`, data, {
  headers: { scope: 'create:zosi_users' },
});

export const clientUsersListPagination = async (
  key,
  q,
  pageNo,
  pageSize,
  sColumn,
  sOrder,
) => axios.get(`${REACT_APP_API_GATEWAY_URL}/company/users`, {
  params: {
    q,
    pageNo,
    pageSize,
    sColumn,
    sOrder,
  },
  headers: { scope: 'read:client_users' },
});

export const clientUserDetails = async (id) => axios.get(`${REACT_APP_API_GATEWAY_URL}/company/users/${id}`, {
  headers: { scope: '' },
});

export const resetUserPassword = async (payload) => {
  const { email } = payload;
  const options = {
    method: 'POST',
    url: `https://${REACT_APP_AUTH0_DOMAIN}${AUTH0_ADMIN_RESET_USER_PASSWORD}`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: CONNECTION,
    },
  };
  return axios.request(options);
};

export const updateUser = async ({ id, data }) => axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/company/users/${id}`, data, {
  headers: { scope: 'update:client_users' },
});

export const updateOwnProfile = async ({ id, data }) => axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/user-profile/${id}`, data, {
  headers: { scope: '' },
});

export const getAuth0Users = async ({ q, id }) => axios.get(`${REACT_APP_API_GATEWAY_URL}/auth-users`, {
  params: {
    q,
    subscriptionId: id,
  },
  headers: { scope: '' },
});

export const listProducts = async (queryKey) => {
  const params = {
    q: queryKey[0],
    type: queryKey[1],
  };

  return axios.get(`${REACT_APP_API_GATEWAY_URL}/products`, {
    params,
    headers: { scope: 'read:products' },
  });
};

export const getUserProductDetails = async (userId, productId, orderId, isDetailReqdForUnassignedProduct = false) => axios.get(`${REACT_APP_API_GATEWAY_URL}/users/${userId}/products/${productId}`, {
  headers: { scope: 'read:user_products' },
  params: {
    orderId,
    isDetailReqdForUnassignedProduct,
  },
});

export const verifyProductOnZuora = async ({ productId, productType }) => axios.get(`${REACT_APP_API_GATEWAY_URL}/zuora/products/${productId}/verify?type=${productType}`, {
  headers: { scope: 'read:products' },
});

export const createOrderOnZuora = async ({ userId, orders }) => axios.post(`${REACT_APP_API_GATEWAY_URL}/zuora/users/${userId}/orders`, orders, {
  headers: { scope: 'create:zuora_order' },
});

export const listLibraryCourses = async (id) => axios.get(`${REACT_APP_API_GATEWAY_URL}/libraries/${id}/courses`, {
  headers: { scope: 'read:libraries' },
});

export const downloadTrainingRecords = async () => axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/user-profile/training-records/download`, {
  headers: {
    scope: '',
    Accept: 'text/csv',
    'Content-Type': 'text/csv',
  },
  responseType: 'blob',
});

export const getUserBundleProductDetails = async (userId, subscriptionId) => axios.get(`${REACT_APP_API_GATEWAY_URL}/users/${userId}/subscriptions/${subscriptionId}`, {
  headers: { scope: 'read:user_products' },
});

import React, { useEffect, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import IdleTimer from '../IdleTimer';
import { NAMESPACE } from '../../constants/apiConstants';
import { checkPermissions, getCompanies } from '../../helper/userMethods';
import { CLIENT_ID } from '../../constants/appConstants';

import ClientSelection from '../../containers/ClientSelection';

const RouteWithLayout = (props) => {
  const {
    layout: Layout, component: Component, appProps, path, disableMenu, hideTopBarClient,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout disableMenu={disableMenu} hideTopBarClient={hideTopBarClient}>
        <ErrorBoundary key={path}>
          <Suspense fallback={<Loading isLoading withBackDrop={false} />}>
            <Component {...appProps} />
          </Suspense>
        </ErrorBoundary>
      </Layout>
    </>
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.oneOfType(
    [PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.instanceOf(Object)],
  ).isRequired,
  layout: PropTypes.func.isRequired,
  appProps: PropTypes.instanceOf(Object),
  path: PropTypes.string,
  disableMenu: PropTypes.bool,
  hideTopBarClient: PropTypes.bool.isRequired,
};

RouteWithLayout.defaultProps = {
  path: '',
  appProps: null,
  disableMenu: false,
};
const WithIdleTimer = (props) => {
  const { user } = useAuth0();
  const isClientSelectionReqd = checkPermissions(user, ['read:all_companies', 'read:selected_companies']);
  let clientId = '';
  if (checkPermissions(user, ['read:selected_companies']) && getCompanies(user).length === 1) {
    localStorage.setItem(
      CLIENT_ID,
      JSON.stringify({ id: getCompanies(user)[0] }),
    );
  }
  if (isClientSelectionReqd && localStorage.getItem(CLIENT_ID)) {
    clientId = JSON.parse(localStorage.getItem(CLIENT_ID)).id === 0
      ? null : localStorage.getItem(CLIENT_ID);
  } else {
    clientId = null;
  }
  let updatedProps = { ...props, hideTopBarClient: true };
  const isLearnerModeEnabled = useFeatureToggle('enableLearnerMode');

  if (isClientSelectionReqd && !clientId && !isLearnerModeEnabled) {
    updatedProps = {
      ...updatedProps,
      component: ClientSelection,
      disableMenu: true,
    };
  } else if (isClientSelectionReqd) {
    updatedProps = {
      ...updatedProps,
      hideTopBarClient: false,
    };
  }

  return (
    <>
      <IdleTimer />
      <RouteWithLayout {...updatedProps} />
    </>
  );
};

export const AuthenticateForAdmin = withAuthenticationRequired(WithIdleTimer, {
  onRedirecting: () => <Loading isLoading />,
});
/* checking for all reqd fields are filled or not.
   If not route to welcome page to fill all reqd fileds */
const validateUserData = (usr) => {
  const isProfileFulfilled = localStorage.getItem('IS_PROFILE_FULFILLED');
  const { family_name: lastName, given_name: firstName, nickname: displayName } = usr;
  const { language, consentGiven = '' } = usr[`${NAMESPACE}user_metadata`];
  const username = usr[`${NAMESPACE}username`];
  if ((!firstName
    || !lastName
    || !displayName
    || Object.keys(language).length === 0
    || !consentGiven
    || !username) && !isProfileFulfilled) return true;
  return false;
};

export const PrivateLayoutRoute = (props) => {
  const {
    isAuthenticated, isLoading, user,
  } = useAuth0();

  // if user doesn't seem authenticated, we'll try to silently login
  // in case there was an SSO token from a login elsewhere

  // here we may redirect the user to different locations based on current state
  if (isLoading) return <Loading isLoading withBackDrop={false} />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (validateUserData(user)) return <Navigate to="/welcome" />;
  return <WithIdleTimer {...props} />;
};

export default RouteWithLayout;

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from '../themes';
import useAppContext from '../hooks/useAppContext';
import '../assets/css/index.css';

export default function AppThemeProvider({ children }) {
  const { theme } = useAppContext();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={{ ...getTheme(theme) }}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
